import {Fragment, useCallback, useEffect, useRef, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useIntl} from 'react-intl'
import purchaseBundleApi from '../../../../api/purchase-bundle.api'
import DynamicObject from '../../../../models/dynamic-object'
import {showSuccess} from '../../../helper/SystemAlert'
import {KTSVG} from '../../../helpers'
import {getGeneralImage, isDeviceMobile, logout} from '../../../helpers/commonFunctions'
import {useLang} from '../../../i18n/OpenSooqi18n'
import {MyListingInterface} from '../../../interfaces/MyListingInterface'
import {Loader} from '../../../layout/components/opensooq/Loader'
import {handleError} from '../../common/HandleError'
import {UseBundleLoader} from '../../common/UseBundleLoader'
import getBundleInfo from '../../../../api/wallet/getBundleInfo'
import {textFormatter} from './Bundles'
import Swal from 'sweetalert2'
import {useNavigate, useParams} from 'react-router-dom'
import DesktopBundleCard from './DesktopBundleCard'
import MobileBundleCard from './MobileBundleCard'
import {SearchComponent} from '../../../layout/components/opensooq/SearchComponent'

type Confirmation = {
  icon: string
  title: string
  description: string
}

export default function BundleUsage() {
  const iframeContent = useRef<null | HTMLIFrameElement>(null)
  const Intl = useIntl()
  const lang = useLang()
  const [loader, setLoader] = useState<boolean>(true)
  const [listings, setListings] = useState<MyListingInterface[]>([])
  const [paginatedListings, setPaginatedListings] = useState<MyListingInterface[]>([])
  const [totalListings, setTotalListings] = useState<number>(0)
  const [bundleInfo, setBundleInfo] = useState<any>()
  const [selectedListingMap, setSelectedListingMap] = useState<DynamicObject>({})
  const [remainingQty, setRemainingQty] = useState<number>(0)
  const [confirmation, setConfirmation] = useState<Confirmation | null>(null)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const search = useRef<string>('')
  const timeoutRef = useRef<any>()
  let initialQuantity = useRef(0)
  const page = useRef(1)
  const navigate = useNavigate()

  const bundleID = useParams().id

  useEffect(() => {
    if (bundleID) {
      getBundleInfo(`userBundleId=${bundleID}&page=1&perPage=30`)
        .then((response) => {
          setConfirmation(response.data?.confirmation)
          setBundleInfo(response.data)
          setRemainingQty(response.data?.bundle?.availableQuantity)
          initialQuantity.current = response.data?.bundle?.availableQuantity
          page.current = 2
        })
        .catch((error) => {
          handleError(error, lang, Intl)
          navigate('/account/my-wallet')
        })
    }
  }, [])

  const changeSelectListingHandler = useCallback((id: number) => {
    setSelectedListingMap((cur) => {
      const isSelected = cur[id]
      if (!isSelected && remainingQty <= 0) return cur
      setRemainingQty((prev) => {
        return isSelected ? prev + 1 : prev - 1
      })
      const next = {...cur, [id]: !isSelected}
      return next
    })
  }, [remainingQty])

  useEffect(() => {
    if(!selectedListingMap) return
    for (const list in listings) {      
      if (listings[list].show_popup && selectedListingMap[listings[list].listing_id]) {
        setShowPopup(true)
        return
      }
    }    
    setShowPopup(false)

  }, [selectedListingMap, listings])
    

  const clickApplyLogic = () => {
    let selectedListings = []
    for (const [key, value] of Object.entries(selectedListingMap)) {
      if (value) {
        selectedListings.push(key)
      }
    }
    setLoader(true)
    purchaseBundleApi({
      data: {
        id: bundleID,
        posts: selectedListings,
      },
    }).then((response) => {
      setLoader(false)
      //showSuccess(Intl, Intl.locale)
      //navigate('/account/my-wallet')
      const modelId = response?.data?.modelId;
      const paymentUrl = modelId 
      ? `https://payment.${process.env.REACT_APP_DOMAIN_BASE}/payment/screen/0/0/${modelId}?lang=${lang}&referrerUrl=https://my.${process.env.REACT_APP_DOMAIN_BASE}/account/my-wallet`
      : `https://payment.${process.env.REACT_APP_DOMAIN_BASE}/payment/screen?lang=${lang}&referrerUrl=https://my.${process.env.REACT_APP_DOMAIN_BASE}/account/my-wallet`;
      window.location.href = paymentUrl;

    }).catch((error) => {
      setLoader(false);
      console.error("Purchase bundle API error:", error);
    });
  }


   

  const clickApplyHandler = useCallback(() => {
    if (!showPopup) return clickApplyLogic()

    Swal.fire({
      html: `<div class=" d-flex flex-column"><img style="width: 35px; height: 35px" class="align-self-center mb-3" src=${getGeneralImage(
        confirmation.icon
      )} /><p class="fs-2 fw-bolder">${confirmation.title}</p><p class="fs-5 text-start">${
        confirmation.description
      }</p></div>`,
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: Intl.formatMessage({id: 'no'}),
      showLoaderOnConfirm: true,
      confirmButtonText: Intl.formatMessage({id: 'yes'}),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light-danger',
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        clickApplyLogic()
      }
    })
  }, [selectedListingMap, showPopup])

  const isAllowedToSelect = remainingQty > 0

  useEffect(() => {
    if (bundleInfo && bundleInfo.bundle.id) {
      setListings(bundleInfo.listings.items)
      setTotalListings(bundleInfo.listings._meta.totalCount)
      setPaginatedListings(bundleInfo.listings.items)
      setLoader(false)
    }
  }, [bundleInfo])

  window.addEventListener(
    'message',
    (event) => {
      if (event.data === 401) {
        logout()
      } else if (event.data == 200) {
        setLoader(false)
        if (iframeContent.current) {
          iframeContent.current.classList.remove('d-none')
        }
      }
    },
    false
  )

  const setSearch = (e: any) => {
    window.scrollTo(0, 0)
    setIsSearching(true)
    search.current = e.target.value
    page.current = 1
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      getBundleInfo(`userBundleId=${bundleID}&page=1&perPage=30&searchTerm=${e.target.value}`)
        .then((response) => {
          setListings(response.data.listings.items)
          setPaginatedListings(response.data.listings.items)
          setTotalListings(response.data.listings._meta.totalCount)
          page.current = 2
        })
        .catch((error) => {
          handleError(error, lang, Intl)
        })
        .finally(() => {
          setIsSearching(false)
        })
    }, 100)
  }

  const pagination = () => {
    getBundleInfo(
      `userBundleId=${bundleID}&page=${page.current}&perPage=30&searchTerm=${search.current}`
    )
      .then((response) => {
        if (response.data.listings.items) {
          page.current++
          setListings((prevAds) => {
            return [...prevAds, ...response.data.listings.items]
          })
          setPaginatedListings((prevAds) => {
            return [...prevAds, ...response.data.listings.items]
          })
        }
      })
      .catch((error) => {
        handleError(error, lang, Intl)
      })
  }

  const DesktopCard = (listing: DynamicObject, index: number) => {
    const isSelected = selectedListingMap[listing.listing_id]
    const isDisabled = !isSelected && !isAllowedToSelect
    return (
      <DesktopBundleCard
        listing={listing}
        index={index}
        isSelected={isSelected}
        isDisabled={isDisabled}
        changeSelectListingHandler={changeSelectListingHandler}
        selectedListingMap={selectedListingMap}
        isAllowedToSelect={isAllowedToSelect}
      />
    )
  }

  const Circle = useRef(
    <img src='/media/icons/duotune/general/Circle.png' height='20' width='20' alt='' />
  )
  const SelectedCircle = useRef(
    <img src='/media/icons/duotune/general/Selected-Circle.png' height='20' width='20' alt='' />
  )
  const DisabledCircle = useRef(
    <img src='/media/icons/duotune/general/Disabled-Circle.png' height='20' width='20' alt='' />
  )

  const MobileCard = (listing: DynamicObject, index: number) => {
    const isSelected = selectedListingMap[listing.listing_id]
    const isDisabled = !isSelected && !isAllowedToSelect
    return (
      <MobileBundleCard
        listing={listing}
        index={index}
        Icon={
          isSelected ? SelectedCircle.current : isDisabled ? DisabledCircle.current : Circle.current
        }
        changeSelectListingHandler={changeSelectListingHandler}
        selectedListingMap={selectedListingMap}
        isAllowedToSelect={isAllowedToSelect}
      />
    )
  }

  const CardsContainer = () => {
    return (
      <>
        {isSearching ? (
          <>
            <Loader />
            {isDeviceMobile() && <div style={{height: '270px', backgroundColor: '#F5F8FA'}}></div>}
          </>
        ) : isDeviceMobile() ? (
          <div className='d-flex flex-column gap-10 my-7' style={{backgroundColor: '#F5F8FA'}}>
            {paginatedListings.map((listing: DynamicObject, index) => {
              return MobileCard(listing, index)
            })}
          </div>
        ) : (
          <div className='d-flex flex-column gap-5 my-5'>
            {paginatedListings.map((listing: DynamicObject, index) => {
              return (
                <Fragment key={index}>
                  {DesktopCard(listing, index)}
                  {index != paginatedListings.length - 1 && (
                    <div style={{borderTop: 'dotted 1px #57585A', borderColor: '#D1D1D6'}} />
                  )}
                </Fragment>
              )
            })}
          </div>
        )}
      </>
    )
  }

  return (
    <div>
      {loader ? (
        <UseBundleLoader />
      ) : (
        <>
          <div className='bg-white'>
            <div className={`${isDeviceMobile() ? 'py-4 my-2' : 'p-5'}`}>
              <div className='modal-title w-100'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {bundleInfo && (
                        <span className='text-dark fw-bold text-hover-primary fs-3'>
                          <img src={getGeneralImage(bundleInfo.headerIcon)} className='w-25px' />{' '}
                          {bundleInfo.headerText}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='py-lg-2 px-lg-10 align-items-center'>
              <p className='text-black fw-bolder fs-4'>{bundleInfo.subHeaderText}</p>
              <p
                className='hoverPointer'
                onClick={() => {
                  window.open(bundleInfo.hintLink, '_blank')
                }}
              >
                {bundleInfo.hintText}
              </p>
              <p className='text-black fw-bolder '>{bundleInfo.labelText}</p>

              {bundleInfo?.bundle && (
                <div className='fs-4 text-dark shadow-sm p-4 w-100 mt-4 border rounded-3'>
                  <div className='d-flex gap-3'>
                    <img
                      height={25}
                      width={25}
                      src={getGeneralImage(bundleInfo.bundle?.icon)}
                      alt=''
                    />
                    <div className='d-flex flex-column gap-2'>
                      <span className='fs-3 fw-bold'>{bundleInfo?.bundle?.label}</span>
                      <span className='fs-7 fw-bold text-muted'>
                        {bundleInfo?.bundle?.catLabel}
                      </span>
                      <span className='fs-7 text-muted'>{bundleInfo?.bundle?.expiryLabel}</span>
                    </div>
                  </div>
                  <div className='my-2 fs-4 p-4'>
                    <strong className='fs-3'>{remainingQty}</strong>{' '}
                    {Intl.formatMessage({id: 'listingsLeft'})}
                  </div>
                  {bundleInfo?.bundle?.features && (
                    <>
                      {' '}
                      <div
                        className='vas-mb-3 vas-mt-4'
                        style={{borderBottom: `1px dashed #D3D3D3`}}
                      />
                      <div
                        className='fs-7 px-5 d-flex flex-column gap-2'
                        style={{margin: '0px 25px'}}
                      >
                        {bundleInfo?.bundle?.features.map((item, index) => {
                          return (
                            <span key={index}>
                              <KTSVG
                                className='svg-icon-7'
                                path='/media/svg/general/green-check.svg'
                              />{' '}
                              <span className='mx-1'>{textFormatter(item)}</span>
                            </span>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              )}
              <div className='bg-white'>
                <div
                  className='fs-4 text-dark w-100 shadow-sm my-4 rounded-3'
                  style={{
                    position: 'sticky',
                    top: isDeviceMobile() ? '7%' : '8%',
                    zIndex: '5',
                  }}
                >
                  <SearchComponent
                    action={setSearch}
                    placeholder={`${Intl.formatMessage({id: 'myWallet.bundleUsage.search'})}`}
                    className='w-100 p-0'
                    inputClassName='min-w-100 bg-white'
                  />
                </div>
                <div>
                  <InfiniteScroll
                    style={{overflow: 'clip'}}
                    next={pagination}
                    dataLength={paginatedListings.length}
                    hasMore={totalListings > paginatedListings.length}
                    loader={<Loader />}
                    refreshFunction={undefined}
                    pullDownToRefresh={false}
                    pullDownToRefreshThreshold={100}
                    pullDownToRefreshContent={null}
                    releaseToRefreshContent={null}
                  >
                    {listings.length == 0 && (
                      <div className='text-center'>
                        <h4>{Intl.formatMessage({id: 'noMatchingListings'})}</h4>
                        {isDeviceMobile() && (
                          <div style={{height: '260px', backgroundColor: '#F5F8FA'}}></div>
                        )}
                      </div>
                    )}
                    {listings.length > 0 && CardsContainer()}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
            {listings.length > 0 && (
              <div className='modal-footer py-4'>
                <div className='row w-100 justify-content-center'>
                  <div className='col-lg-4 col-6'>
                    <div className={`d-flex align-items-center justify-content-center`}></div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className='d-flex justify-content-between bg-white w-100 shadow p-3 rounded-1'
            style={{position: 'sticky', bottom: '2px'}}
          >
            {bundleInfo?.bundle && (
              <div className='text-dark px-2'>
                <div className='d-flex gap-3'>
                  <img
                    height={20}
                    width={20}
                    src={getGeneralImage(bundleInfo.bundle?.icon)}
                    alt=''
                  />
                  <div className='d-flex flex-column gap-1'>
                    <span className='fs-6 text-muted fw-bold'>{bundleInfo?.bundle?.label}</span>
                    <span className='fs-5'>
                      <strong className='text-primary fs-3'>{remainingQty}</strong>{' '}
                      <strong className='text-dark'>
                        {Intl.formatMessage({id: 'listingsLeft'})}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <button
              onClick={clickApplyHandler}
              disabled={remainingQty === initialQuantity.current}
              className='btn btn-primary btn-sm fw-bold fs-6 h-40px align-self-center px-10'
            >
              {Intl.formatMessage({id: 'myWalletText10'})} ({initialQuantity.current - remainingQty}
              )
            </button>
          </div>
        </>
      )}
    </div>
  )
}
