import { TableRecordDataType, TableRecordType } from '../types/ScrollableOverdueListType'
import './AmountDue.css'

const TableRecord = ({
  data,
  contFunction,
  key,
}: {
  data: TableRecordDataType
  contFunction: (payload: any) => void
  key: string
}) => {
  const checkbox = data.checkbox
  const overdue = data.overdue
  const subtitle = data.subtitle
  const title = data.title
  // const checkBoxBGColor = checkbox?.disabled ? '#575757' : checkbox?.isChecked ? '#0379FE' : 'gray'

  const toggleHandler = () => {
    if (!checkbox || checkbox?.disabled) return
    contFunction(checkbox?.payload)
  }

  return (
    <div className='d-flex flex-column m-0'>
      {checkbox?.hideCheckbox ? (
                <>
                <div className='w-100 p-2 px-4 d-flex justify-content-between align-items-start'>
                  <div className='d-flex gap-3 align-items-start'>
                    <div className='d-flex gap-1 flex-column'>
                      <span
                        className={`fs-5 ${title?.style?.bold ? 'fw-lighter' : ''} ${
                          title?.style?.underline ? 'text-decoration-underline' : ''
                        }`}
                        style={{ color: title?.textColor }}
                      >
                        {title?.text ?? ''}
                      </span>
                      <span
                        className={`fs-5 text-muted ${
                          subtitle?.style?.bold ? 'fw-bold' : ''
                        } ${subtitle?.style?.underline ? 'text-decoration-underline' : ''}`}
                        style={{ color: title?.textColor }}
                      >
                        {subtitle?.text ?? ''}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`fs-3 ${overdue?.style?.bold ? 'fw-bold' : ''} ${
                      overdue?.style?.underline ? 'text-decoration-underline' : ''
                    }`}
                    style={{ color: "rgba(87, 88, 90, 1)" }}
                  >
                    {overdue?.text ?? '-.- JOD'}
                  </div>
                </div>
                <div className="status-box mx-3">
                <strong>{data?.note?.title ?? ''}</strong>
                {data?.note?.subTitle ?? ''}
              </div>
                <hr
                  className='mt-4'
                  style={{ height: '1px', border: '0 none', color: '#888', backgroundColor: '#88888875' }}
                />
              </>
      ) : (
        <>
          <div className='w-100 p-2 px-4 d-flex justify-content-between align-items-start'>
            <div className='d-flex gap-3 align-items-start'>
            {/* <i
  onClick={toggleHandler}
  className={`${checkbox?.disabled ? '' : 'hoverPointer'} ${
    checkbox?.isChecked ? 'fa-solid fa-square-check' : 'fa-regular fa-square'
  } fs-1`}
  style={{
    color: checkBoxBGColor,
     
  }}
></i> */}
<label
  onClick={checkbox?.disabled ? null : toggleHandler} 
  className={`
    ${checkbox?.disabled
      ? (checkbox?.isChecked ? 'ModalCheckboxDisabled' : 'ModalCheckboxDisabledUnchecked')
      : (checkbox?.isChecked ? 'ModalCheckboxChecked' : 'ModalCheckbox')}
    ${checkbox?.disabled ? '' : 'hoverPointer'}
  `}
></label>
              <div className='d-flex gap-1 flex-column'>
                <span
                  className={`fs-5 ${title?.style?.bold ? 'fw-lighter' : ''} ${
                    title?.style?.underline ? 'text-decoration-underline' : ''
                  }`}
                  style={{ color: title?.textColor }}
                >
                  {title?.text ?? ''}
                </span>
                <span
                  className={`fs-5 text-muted ${
                    subtitle?.style?.bold ? 'fw-bold' : ''
                  } ${subtitle?.style?.underline ? 'text-decoration-underline' : ''}`}
                  style={{ color: title?.textColor }}
                >
                  {subtitle?.text  ?? ''}
                </span>
              </div>
            </div>
            <div
              className={`fs-3 ${overdue?.style?.bold ? 'fw-bold' : ''} ${
                overdue?.style?.underline ? 'text-decoration-underline' : ''
              }`}
              style={{ color: overdue?.textColor }}
            >
              {overdue?.text ?? '-.- JOD'}
            </div>
          </div>
          <hr
            className='mt-0'
            style={{ height: '1px', border: '0 none', color: '#888', backgroundColor: '#88888875' }}
          />
        </>
      )}
    </div>
  )
}

export default TableRecord
