import {generateStyleClasses, styleHelper, formatPrice} from '../helper'
import {
  getAddPostIntegrationUrl,
  getCookies,
  getNoImageLogo,
  setBackURLForAddPost,
} from '../../../../helpers/commonFunctions'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import {useIntl} from 'react-intl'
import RadialBarChart from '../../new-my-listings/RadialBarChat'
import {ListingInterfaceMobile, POST_ACTIONS, PostActionInput} from './lisitingtypes'
import {useNavigate} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'
import {useChatFunctions} from 'opensooq-chat'
import {KTSVG} from '../../../../helpers'
import {
  logMyListingsQualityListingClick,
  logMyListingsStatsListingClick,
  logMyListingsViewListingClick,
} from '../../../../../logging/helpers/commonLoggingFunctions'
import {EnumUxTypes} from 'opensooq-chat/dist/esm/logging/types/LoggingTypes'

type Props = {
  data: ListingInterfaceMobile
  handlePostAction: ({action, listing, options}: PostActionInput) => void
}

export default function NewMyListingsMobile({data, handlePostAction}: Props) {
  const lang = useLang()
  const Intl = useIntl()
  const {getUnreadPostRooms} = useChatFunctions()
  const [unReadChatsCount, setUnReadChatsCount] = useState<number>(0)
  const navigate = useNavigate()

  const chartOptionsRef = useRef({
    width: window.innerWidth <= 375 ? 152 : 170,
    height: window.innerWidth <= 375 ? 145 : 160,
    nameFontSize: window.innerWidth <= 375 ? '15px' : '17px',
    valueFontSize: window.innerWidth <= 375 ? '15px' : '17px',
    totalFontSize: window.innerWidth <= 375 ? '10px' : '13px',
    hollowSize: window.innerWidth <= 375 ? '57%' : '63%',
  })

  const handleImage = (imgUri) => {
    if (!imgUri) return getNoImageLogo(lang)
    return process.env.REACT_APP_ARBOK_URL + '200x0/' + imgUri + '.jpg' || ''
  }

  const ImageMediaIcons = () => {
    return (
      <div className='d-flex gap-2 ms-1 listing-media-icons-container'>
        {data.listingImageCount > 0 && (
          <span className='listing-media-icons'>{data.listingImageCount}</span>
        )}
        {data.mediaIcons?.map((mediaType, index) => {
          return (
            <span className='listing-media-icons' key={index}>
              <img src={mediaType} width={12} height={12} alt='' />
            </span>
          )
        })}
      </div>
    )
  }

  const onClickListingHandler = (e: any) => {
    redirectToPost(e, `/listings/my-listings/listing-view?id=${data.listingId}`)
  }

  const redirectToPost = (e: any, url: string) => {
    if (!e.target.classList.contains('action')) {
      logMyListingsViewListingClick(data.loggingData, 'listing_cell', EnumUxTypes.LIST_CELL)
      window.open(url, '_blank')
    }
  }

  const redirectToEditPost = (type: string) => {
    if (!data.quality.isEnabled) return
    setBackURLForAddPost()
    logMyListingsQualityListingClick(data.loggingData, 'listing_cell', EnumUxTypes.LINK)
    window.location.href = window.location.href = getAddPostIntegrationUrl(
      getCookies().NEXT_LOCALE,
      type,
      data.listingId ?? 0,
      0,
      false
    )
  }

  const getUnreadChat = (listingId: number) => {
    getUnreadPostRooms(listingId)
      .then((count: number) => {
        setUnReadChatsCount(count)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  useEffect(() => {
    getUnreadChat(data?.listingId)
  }, [])

  const redirectToStats = () => {
    logMyListingsStatsListingClick(data.loggingData, 'wallet_cell', EnumUxTypes.BUTTON)
    setTimeout(() => {
      navigate(`/listings/my-listings/listing-stats?id=${data.listingId}`)
    }, 0)
  }

  const cpsData = data?.strs?.split(/,\s*/);
  const adjustedCpsData = cpsData?.reduce((acc, item) => {
      const trimmedItem = item.trim()

      // Replace '1' with 'Delivery Option'
      if (trimmedItem === '1') {
        acc.push(Intl.formatMessage({id: 'adjustedCpsMessageDeliveryOption'}))
      } else if (trimmedItem) {
        acc.push(trimmedItem)
      }

      return acc
    }, []).filter((item) => item !== '').join(lang === 'ar' ? ' ،\u200F ' : ', ');
  return (
    <div style={{maxWidth: '375px'}} className='card py-5 d-flex flex-column gap-4 w-100'>
      <div className='d-flex justify-content-between px-5 '>
        <span data-id={`my-listing-id-${data.listingId}`}>
          {' '}
          {Intl.formatMessage({id: 'mylistings.container.listingId'})}: {data?.listingId ?? ''}
        </span>
        <span
          style={styleHelper({
            color: data.listingStatus?.textColor,
            backgroundColor: data.listingStatus?.backgroundColor,
          })}
          data-id={`my-listing-status-${data.listingId}`}
          className={`${generateStyleClasses(data.listingStatus?.style)} px-3 pt-1 rounded-1`}
        >
          {data.listingStatus?.text ?? ''}
        </span>
      </div>
      <div
        id='listing-info'
        data-id={`my-listing-info-${data.listingId}`}
        className='d-flex gap-2 hoverPointer px-5'
        onClick={(e) => onClickListingHandler(e)}
      >
        <div className='d-flex flex-column'>
          <img
            src={handleImage(data.listingImage)}
            width={80}
            height={80}
            className='rounded-3 customImg'
            alt=''
          />
          {ImageMediaIcons()}
        </div>
        <div className='d-flex flex-column gap-1 w-75'>
          <span
            id={`myListingSnippet-${data.listingId}`}
            style={styleHelper({
              color: data.title?.textColor,
            })}
            className={`${generateStyleClasses(data.title?.style)}  text-truncate w-75`}
          >
            {data.title.text ?? ''}
          </span>
          <span className='customPostTitleText' style={{height: '40px', lineHeight: '1.6'}}>
            {adjustedCpsData}
          </span>
          <span
            style={styleHelper({
              color: data.price?.textColor,
            })}
            className={`${generateStyleClasses(data.price?.style)}`}
          >
            {data.price?.text ? formatPrice(data.price.text) : ''}
          </span>
        </div>
      </div>

      <div className='d-flex flex-wrap gap-2 px-5'>
        {!data.isRejected &&
            data.flags.map((flag) => {
              return (
                  <div key={flag?.text}
                      data-id={`my-listing-flags-${data.listingId}-${flag?.text}`}
                      className={`d-flex rounded-3 p-1 me-1`}>
                    {flag.icon && (
                     <span>
                  <img src={flag.icon} width={14} height={14} alt='flag icon' className='me-2'/>
                    </span>
                    )}

                    <span style={styleHelper({
                          color: flag.textColor
                        })}
                        className={`${generateStyleClasses(flag.style)}`}>
                  {flag.text ?? ''}
                </span>
                  </div>
              )
            })}
        {data.isRejected && data.rejectionReason && (
          // <span>
          //   <span>{Intl.formatMessage({id: 'mylistings.list.rejectionReason.1'})}</span>
          //   <span
          //     className='fw-bold text-decoration-underline hoverPointer'
          //     onClick={() => handlePostAction({action: POST_ACTIONS.TERMS_OF_USE, listing: data})}
          //   >
          //     {Intl.formatMessage({id: 'mylistings.list.rejectionReason.2'})}
          //   </span>
          //   <span>{Intl.formatMessage({id: 'mylistings.list.rejectionReason.3'})}</span>
          // </span>
          <span
            style={styleHelper({
              color: data.rejectionReason?.textColor,
              backgroundColor: data.rejectionReason?.backgroundColor,
            })}
            data-id={`my-listing-status-${data.listingId}`}
            className={`hoverPointer px-3 pt-1 rounded-1`}
          >
            {data.rejectionReason && data.rejectionReason?.icon && (
              <img
                src={data.rejectionReason?.icon}
                width={14}
                height={14}
                alt=''
                className='me-2'
              />
            )}
            <span className={`${generateStyleClasses(data.rejectionReason?.style)}`}>
              {data.rejectionReason?.text ?? ''}
            </span>
          </span>
        )}
      </div>

      <div className='dotted-line py-1 my-2' />

      <div className='d-flex justify-content-between align-items-center px-5'>
        <div
          className='quality-mobile-chart-container me-8'
          onClick={() => redirectToEditPost('edit')}
          data-id={`my-listing-quality-${data.listingId}`}
          style={{
            marginTop: `${window.innerWidth <= 375 ? '1px' : ''}`,
          }}
        >
          <RadialBarChart
            percentage={parseInt(data.quality?.text || '0')}
            value={data.quality?.text || '0'}
            width={chartOptionsRef.current?.width}
            height={chartOptionsRef.current?.height}
            key={data.listingId}
            label='mylistings.container.quality'
            fillColor='#28B936'
            nameFontSize={chartOptionsRef.current?.nameFontSize}
            valueFontSize={chartOptionsRef.current?.valueFontSize}
            totalFontSize={chartOptionsRef.current?.totalFontSize}
            hollowSize={chartOptionsRef.current?.hollowSize}
          />
        </div>
        <div className='d-flex flex-column text-center'>
          <span
            className={`fw-bold fs-3`}
            style={styleHelper({
              color: data.leads?.totalViews?.textColor,
            })}
            data-id={`my-listing-views-${data.listingId}`}
            onClick={redirectToStats}
          >
            {data.leads?.totalViews?.text}
          </span>
          <span className=''>{Intl.formatMessage({id: 'mylistings.container.views'})}</span>
        </div>

        <div className='d-flex flex-column text-center'>
          <span
            className={`fw-bold fs-3`}
            style={styleHelper({
              color: data.leads?.totalLeads?.textColor,
            })}
            data-id={`my-listing-leads-${data.listingId}`}
            onClick={redirectToStats}
          >
            {data.leads?.totalLeads?.text}
          </span>
          <span className=''>{Intl.formatMessage({id: 'mylistings.container.leads'})}</span>
        </div>

        {/* <button className='btn btn-primary fw-bold'>Promote</button>
         */}
        {data.leadsActions && (
          <button
            style={styleHelper({
              color: data.leadsActions?.textColor,
              backgroundColor: data.leadsActions?.backgroundColor,
            })}
            data-id={`my-listing-leads-actions-${data.listingId}-${data.leadsActions?.actionType}`}
            onClick={() => {
              if (data.leadsActions?.isActive)
                handlePostAction({action: data.leadsActions?.actionType, listing: data})
            }}
            className={`${generateStyleClasses(data.leadsActions?.style)} btn`}>
            {data.leadsActions.text}
          </button>
        )}
      </div>
      <div className='dotted-line py-1 my-2' />

      {data.applicantsStatus && (
        <>
          <div
            style={styleHelper({
              color: data.applicantsStatus?.textColor,
            })}
            data-id={`my-listing-status-${data.listingId}`}
            className={`hoverPointer px-3 pt-1 d-flex justify-content-between align-items-center`}
            onClick={() =>
              handlePostAction({action: data.applicantsStatus?.actionType, listing: data})
            }
          >
            <span className={`fw-bold fs-2`}>{data.applicantsStatus?.text ?? ''}</span>

            <span style={{transform: `scale(${lang == 'en' ? '1' : '-1'}, 1)`}}>
              <KTSVG className='svg-icon-1' path='/media/svg/shapes/chevron.svg' />
            </span>

            {/* <span className='p-2 fs-1'>

            <i className={`fa ${lang == "en"? "fa-chevron-right": "fa-chevron-left"} p-3`}></i>
            </span> */}
          </div>
          <div className='dotted-line py-1 my-2' />
        </>
      )}

      <div>
        <div id='listing-actions' className='d-flex justify-content-between px-5'>
        {!data.isDeleted ? 
        (
  <>
    {data.postActions.map((action) => {
      if (
        action.actionType === POST_ACTIONS.ACTIVE ||
        action.actionType === POST_ACTIONS.COPY ||
        action.actionType === POST_ACTIONS.DELETE_FOREVER ||
        action.actionType === POST_ACTIONS.VERIFY
      ) {
        return (
          <button
            key={action.text}
            className="btn btn-primary fw-bold fs-2 w-75 d-flex align-items-center gap-2 justify-content-center"
            data-id={`my-listing-actions-${data.listingId}-${action.actionType}`}
            onClick={() =>
              handlePostAction({
                action: action.actionType,
                listing: data,
              })
            }
          >
            <img
              src={action?.icon}
              width={25}
              height={25}
              alt=""
              style={{
                filter: "invert(100%)",
              }}
            />
            {action.text}
          </button>
        );
      } else {
        return (
          <span
            key={action.text}
            style={styleHelper({ color: action.textColor })}
            className="d-flex gap-2 hoverPointer d-flex flex-column align-items-center "
            data-id={`my-listing-actions-${data.listingId}-${action.actionType}`}
            onClick={() =>
              handlePostAction({
                action: action.actionType,
                listing: data,
                options: action?.items,
              })
            }
          >
            <span
              className={`border border-2 rounded-2 p-1 ${
                action.actionType === POST_ACTIONS.CHAT ? "listing-chat_container " : ""
              }`}
            >
              <img src={action?.icon} width={16} height={16} alt="" />
              {action.actionType === POST_ACTIONS.CHAT && (
                <span className="listing-chat_counter fw-bold">
                  {unReadChatsCount <= 99 ? unReadChatsCount : "+99"}
                </span>
              )}
            </span>
            <span className={generateStyleClasses(action.style)}>{action.text ?? ""}</span>
          </span>
        );
      }
    })}
  </>
) 
:
 (
  <>
    {data.postActions
      .filter((action) => action.actionType !== POST_ACTIONS.DELETE_FOREVER) // Exclude DELETE_FOREVER when isDeleted is true
      .map((action) => {
        if (
          action.actionType === POST_ACTIONS.ACTIVE ||
          action.actionType === POST_ACTIONS.COPY ||
          action.actionType === POST_ACTIONS.VERIFY
        ) {
          return (
            <button
              key={action.text}
              className="btn btn-primary fw-bold fs-2 w-75 d-flex align-items-center gap-2 justify-content-center"
              data-id={`my-listing-actions-${data.listingId}-${action.actionType}`}
              onClick={() =>
                handlePostAction({
                  action: action.actionType,
                  listing: data,
                })
              }
            >
              <img
                src={action?.icon}
                width={25}
                height={25}
                alt=""
                style={{
                  filter: "invert(100%)",
                }}
              />
              {action.text}
            </button>
          );
        } else {
          return (
            <span
              key={action.text}
              style={styleHelper({ color: action.textColor })}
              className="d-flex gap-2 hoverPointer d-flex flex-column align-items-center "
              data-id={`my-listing-actions-${data.listingId}-${action.actionType}`}
              onClick={() =>
                handlePostAction({
                  action: action.actionType,
                  listing: data,
                  options: action?.items,
                })
              }
            >
              <span
                className={`border border-2 rounded-2 p-1 ${
                  action.actionType === POST_ACTIONS.CHAT ? "listing-chat_container " : ""
                }`}
              >
                <img src={action?.icon} width={16} height={16} alt="" />
                {action.actionType === POST_ACTIONS.CHAT && (
                  <span className="listing-chat_counter fw-bold">
                    {unReadChatsCount <= 99 ? unReadChatsCount : "+99"}
                  </span>
                )}
              </span>
              <span className={generateStyleClasses(action.style)}>{action.text ?? ""}</span>
            </span>
          );
        }
      })}
  </>
)}

        </div>
      </div>
    </div>
  )
}
