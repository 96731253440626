import {TableHeaderType} from '../types/ScrollableOverdueListType'

const TableHeader = ({data, contFunction}: {data: TableHeaderType; contFunction: (payload: any) => void}) => {
  const checkbox = data?.checkbox
  const text = data?.text
  const title = data?.title
  const checkBoxBGColor = checkbox?.disabled ? '#575757' : checkbox?.isChecked ? '#0379FE' : 'white';
  const toggleHandler = ()=>{
    if(checkbox && checkbox.disabled)
      return;
    contFunction(checkbox?.payload);
  }
  return (
    <div id='body_header' className='w-100'>
      <div className='w-100 bg-secondary_new p-1 px-4 d-flex justify-content-between align-items-center ' style={{ borderRadius: '4px' }}>
        <div className='d-flex gap-3 align-items-center bg-secondary_new'>
        <i
  onClick={toggleHandler}
  className={`${checkbox?.disabled ? '' : 'hoverPointer'} fa-solid ${
    checkbox?.isChecked ? 'fa-square-check' : 'fa-square'
  } fs-1`}
  style={{
    color: checkBoxBGColor
  }}
></i>

          <span
            className={`fs-4 ${title?.style?.bold ? 'fw-bold' : ''} ${
              title?.style?.underline ? 'text-decoration-underline' : ''
            }`}
            style={{color: title?.textColor}}
          >
            {title?.text ?? ''}
          </span>
        </div>
        <div
          className={`fs-4 ${text?.style?.bold ? 'fw-bold' : ''} ${
            text?.style?.underline ? 'text-decoration-underline' : ''
          }`}
        >
          {text?.text ?? ''}
        </div>
      </div>
    </div>
  )
}

export default TableHeader
