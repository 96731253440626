import React, { useMemo, useEffect, useState } from 'react'
import { FinalizeButtonDataType } from '../types/FinalizeButtonType'
import { isDeviceMobile } from '../../../../../helpers/commonFunctions'

const FinalizeButton = ({
  data,
  contFunction,
  isLoading,
}: {
  data: FinalizeButtonDataType
  contFunction: (payload: any) => void
  isLoading: boolean
}) => {
  const [isRTL, setIsRTL] = useState(false)

  useEffect(() => {
    // Detect page direction
    setIsRTL(document.dir === 'rtl')
  }, [])

  const clickHandler = () => {
    if (!data || !data.payload) return
    contFunction(data.payload)
  }

  const Loader = useMemo(() => {
    return <i className='spinner-border text-white spinner-border-sm fs-2 p-2' />
  }, [])

  const ReactButton = (
    <button
      className={`btn px-2 btn-lg w-100 d-flex justify-content-between align-items-center fs-3 ${
        isDeviceMobile() ? 'py-6' : ''
      }`}
      style={{ backgroundColor: data?.backgroundColor }}
      disabled={isLoading || !data?.status}
      onClick={clickHandler}
    >
      <span className='d-block d-flex gap-2 align-items-center'>
        {/* Count Box */}
        <span
          className={`border-radius rounded pt-2 px-2 mx-1 ${
            data?.countBox?.style?.bold ? 'fw-bold' : ''
          } ${data?.countBox?.style?.underline ? 'text-decoration-underline' : ''}`}
          style={{
            backgroundColor: data?.countBox?.backgroundColor,
            color: data?.countBox?.textColor,
            width: '40px',
          }}
        >
          {!isLoading ? data?.countBox?.text : Loader}
        </span>

        {/* Content Box + Arrow (Adjust order for RTL) */}
        <span
          className={`mt-1 ${
            data?.contentBox?.style?.bold ? 'fw-bold' : ''
          } ${data?.contentBox?.style?.underline ? 'text-decoration-underline' : ''} 
            d-flex align-items-center gap-2`}
          style={{ color: data?.contentBox?.textColor, flexDirection: isRTL ? 'row-reverse' : 'row' }}
        >
          {!isRTL && (data?.contentBox?.text ?? '')}
          <svg
            width="20"
            height="15"
            viewBox="0 0 20 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: isRTL ? 'rotate(180deg)' : 'none' }} // Flip arrow for RTL
            className={!isRTL&& "mb-1"}
          >
            <path
              d="M13 0.72168L11.59 2.13168L16.17 6.72168H0V8.72168H16.17L11.58 13.3117L13 14.7217L20 7.72168L13 0.72168Z"
              fill="white"
            />
          </svg>
          {isRTL && (data?.contentBox?.text ?? '')}
        </span>
      </span>

      {/* Total Box */}
      <span
        className={`fw-bold mt-1 p-1 ${data?.totalBox?.style?.bold ? 'fw-bold' : ''} ${
          data?.totalBox?.style?.underline ? 'text-decoration-underline' : ''
        }`}
        style={{ color: data?.totalBox?.textColor }}
      >
        {data?.totalBox?.text ?? ''}
      </span>
    </button>
  )

  return (
    <>
      {isDeviceMobile() ? (
        <div className='mt-auto shadow shadow-lg'>{ReactButton}</div>
      ) : (
        <>{ReactButton}</>
      )}
    </>
  )
}

export default FinalizeButton
